import { MessageModalPage } from '../message-modal/message-modal';
import { ModalContentPage } from '../modal-content/modal-content.page';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IonNav,
  ModalController,
  NavParams,
  ToastController
  } from '@ionic/angular';
import { IMessage } from 'models/message';
import { ITest } from 'models/test';
import { Observable, Subscription } from 'rxjs';
import { MessageAdminService } from 'services/message-admin.service';
import { TestAdminService } from 'services/test-admin.service';

@Component({
  selector: 'app-page-turn-around-times',
  templateUrl: 'turn-around-times.html',
  styleUrls: ['turn-around-times.scss']
})
export class TurnAroundTimesPage implements OnInit, OnDestroy {

  message$: Observable<IMessage>;

  test$: Observable<ITest>;
  subscriptions: Subscription;
  test: ITest;

  contentsPage = ModalContentPage;

  constructor(
              public navParams: NavParams,
              private testService: TestAdminService,
              private toastCtrl: ToastController,
              public modalCtrl: ModalController,
              private messageAdminService: MessageAdminService,
              private nav: IonNav
              ) {
  }

  ngOnInit() {
    this.message$ = this.messageAdminService.message$;
    this.subscriptions = this.message$.subscribe();

    this.test$ = this.testService.test$;

    this.subscriptions.add(this.test$.subscribe(t => {
      this.test = t;
    }, error => {
      this.presentToast('No test selected!');
    }));
    console.log('ionViewDidLoad MainDetailsPage');
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    await toast.present();
  }

  async viewMessageModal() {
    const msgModal = await this.modalCtrl.create(
      {
        component: MessageModalPage
      }
    );
    await msgModal.present();
  }

  backToModalContent() {
    this.nav.setRoot(this.contentsPage, { level: 0 });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
