import { Component } from '@angular/core';

/**
 * Generated class for the RefRangeHeadersComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-ref-range-headers',
  templateUrl: 'ref-range-headers.html',
  styleUrls: ['ref-range-headers.scss']
})
export class RefRangeHeadersComponent {

  text: string;

  constructor() {
    console.log('Hello RefRangeHeadersComponent Component');
    this.text = 'Hello World';
  }

}
