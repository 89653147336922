import { IMessage } from './../models/message';
import { MessagesService } from './message.service';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject, tap, share } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageAdminService {

    messageSubject: BehaviorSubject<IMessage> = new BehaviorSubject<IMessage>(null);
    message$: Observable<IMessage> = this.messageSubject.asObservable();

    constructor(private messageService: MessagesService) { }

    broadcastNextMessage(message: IMessage) {
        this.messageSubject.next(message);
    }

    getMessage(): Observable<IMessage> {
      return this.messageService.getMessageById(0).pipe(
        tap(message => this.messageSubject.next(message)),
        share()
      );
    }

    putMessage(message: IMessage): Observable<IMessage> {
      return this.messageService.putMessage(message).pipe(
        tap(m => this.messageSubject.next(m)),
        share()
      );
    }

    deletemessage(message: IMessage): Observable<IMessage> {
      return this.messageService.deleteMessage(message);
    }
}
