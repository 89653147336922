import { IDepartment } from './../models/department';
import { IMessage } from './../models/message';
import { DepartmentService } from './department.service';
import { MessageAdminService } from './message-admin.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  share,
  Subject,
  Subscription,
  tap
  } from 'rxjs';

  @Injectable({
    providedIn: 'root',
  })
export class StartUpService implements OnDestroy {

  departments: IDepartment[];

  message: IMessage;

  public completedStartUp: Subject<boolean> = new Subject();

  public departments$: Observable<IDepartment[]>;
  private departmentsSubject = new BehaviorSubject<IDepartment[]>([]);

  private subscriptions: Subscription;

  constructor(
              private departmentService: DepartmentService,
              private messageAdminService: MessageAdminService
            ) {
    this.departments$ = this.departmentsSubject.asObservable();
  }

  getDepartments() {
    // this.departmentService.getDepartments().pipe(
    //   tap(departments => this.departmentsSubject.next(departments)),
    //   share()
    // );
    this.subscriptions = this.departmentService.getDepartments().pipe(
      tap(departments => this.departmentsSubject.next(departments)),
      share()
    ).subscribe(depts => {
      console.log('departments from startup: ', depts);
    });
  }

  getMessage() {
    this.messageAdminService.getMessage().subscribe(m => {
      this.message = m;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
