export enum TagTypeEnums {
    Code = 'Code',
    Synonym = 'Synonym',
    MainTestName = 'MainTestName',
    ConcurrentTest = 'ConcurrentTest'
}

export enum TimeScales {
    Day = 'D',
    Month = 'M',
    Year = 'Y',
    NotSpecified = 'N/S'
}

export enum GenderEnums {
    Female = 'F',
    Male = 'M',
    NotSpecified = 'N/S'
}
