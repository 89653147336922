import { MessageModalPage } from '../message-modal/message-modal';
import { ModalContentPage } from '../modal-content/modal-content.page';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IonNav, ModalController, ToastController } from '@ionic/angular';
import { IMessage } from 'models/message';
import { ITest } from 'models/test';
import { Observable, Subscription } from 'rxjs';
import { TestAdminService } from 'services/test-admin.service';

@Component({
  selector: 'app-page-references',
  templateUrl: 'references.html',
  styleUrls: ['references.scss']
})
export class ReferencesPage implements OnInit, OnDestroy {

  message$: Observable<IMessage>;

  test$: Observable<ITest>;
  subscriptions: Subscription;
  test: ITest;

  contentsPage = ModalContentPage;

  constructor(
              private testService: TestAdminService,
              private toastCtrl: ToastController,
              public modalCtrl: ModalController,
              private nav: IonNav
              ) {
  }

  ngOnInit() {
    this.test$ = this.testService.test$;
    this.subscriptions = this.test$.subscribe({
      next: t => {
        console.log('test from ref range page', t);
        this.test = t;
      },
      error: () => {
          this.presentToast('No test selected!');
        }
    });
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    await toast.present();
  }

  async viewMessageModal() {
    const msgModal = await this.modalCtrl.create({
      component: MessageModalPage
    });
    await msgModal.present();
  }

  backToModalContent() {
    this.nav.setRoot(this.contentsPage, { level: 0 });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
