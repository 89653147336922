import { QueryObjectService } from './query-object.service';
import { TestService } from './test.service';
import { IQueryObject } from '../models/query-object';
import { IQueryResults, QueryResults } from '../models/query-results';
import { ITest } from '../models/test';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable, share, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TestAdminService {

  previousTestViewed: ITest[];

  previousQuery: IQueryObject;
  previousQueryResults: IQueryResults;

  tests$: Observable<ITest[]>;
  test$: Observable<ITest>;
  componentTest$: Observable<ITest>;
  queryResults$: Observable<IQueryResults>;

  private testsSubject = new BehaviorSubject<ITest[]>([]);
  private testSubject = new BehaviorSubject<ITest>(null);
  private componentTestSubject = new BehaviorSubject<ITest>(null);
  private queryResultsSubject = new BehaviorSubject<IQueryResults>(new QueryResults());


  constructor(private testService: TestService, private queryService: QueryObjectService) {
    this.tests$ = this.testsSubject.asObservable();
    this.test$ = this.testSubject.asObservable();
    this.componentTest$ = this.componentTestSubject.asObservable();
    this.queryResults$ = this.queryResultsSubject.asObservable();

    this.previousTestViewed = new Array<ITest>();
  }

  broadcastLastQueryResults() {
    this.queryResultsSubject.next(this.previousQueryResults);
  }

  addToPreviousTestsViewed(test: ITest) {
    this.previousTestViewed.push(test);
    console.log('addToPreviousTestsViewed: ', test, this.previousTestViewed);
  }

  popAndBroadcastLastViewedTest() {
    if (this.previousTestViewed.length > 1) {
      this.previousTestViewed.pop();
      this.broadcastSelectedTest(this.previousTestViewed[this.previousTestViewed.length - 1]);
    } else if (this.previousTestViewed.length === 1) {
      this.previousTestViewed.pop();
      this.broadcastSelectedTest(null);
    } else {
      this.broadcastSelectedTest(null);
    }
  }

  nullComponentTest() {
    this.componentTestSubject.next(null);
  }

  broadcastSelectedTest(test: ITest) {
    this.testSubject.next(test);
  }

  nullSelectedTest() {
    this.testSubject.next(null);
  }

  getTests(): Observable<ITest[]> {
    return this.testService.getTests().pipe(
      tap(tests => this.testsSubject.next(tests)),
      share()
    );
  }

  getTestById(id: number): Observable<ITest>  {
    return this.testService.getActiveOnlyTestById(id).pipe(
      tap(test => this.testSubject.next(test)),
      share()
    );
  }

  getComponentTestById(id: number): Observable<ITest>  {
    return this.testService.getActiveOnlyTestById(id).pipe(
      tap(test => this.componentTestSubject.next(test)),
      share()
    );
  }

  postTest(test: ITest): Observable<ITest> {
    console.log('container details in admin service: ', test);
    return this.testService.postTest(test).pipe(
      tap(returnedTest => this.testSubject.next(returnedTest)),
      share()
    );
  }

  postQueryResults(queryObject: IQueryObject): Observable<IQueryResults> {
    return this.testService.postTestQuery(queryObject).pipe(
      tap(queryResults => {
        this.previousQuery = queryObject;
        this.previousQueryResults = queryResults;
        this.queryResultsSubject.next(queryResults);
        this.queryService.broadcastNextQueryResults(queryResults);
      }),
      share()
    );
  }

  putTest(test: ITest): Observable<ITest> {
    return this.testService.putTest(test).pipe(
      tap(() => this.testSubject.next(test)),
      share()
    );
  }
}
