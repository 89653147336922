import { Component, Input } from '@angular/core';
import { IContainerDetails } from 'models/container-details';


@Component({
  selector: 'app-container-numbers-display',
  templateUrl: 'container-numbers-display.html',
  styleUrls: ['container-numbers-display.scss']
})
export class ContainerNumbersDisplayComponent {

  @Input()
  containerDetails: IContainerDetails;

  text: string;

  constructor() {
    console.log('Hello ContainerNumbersDisplayComponent Component');
    this.text = 'Hello World';
  }

  createContainerRequiredArray(): number[] {
    const required: number[] = new Array(Number(this.containerDetails.numberOfCollectionContainersRequired));

    return required;
  }

}
