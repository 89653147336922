import { Component, Input } from '@angular/core';
import { ITag } from 'models/tag';

@Component({
  selector: 'app-tag-display',
  templateUrl: 'tag-display.html',
  styleUrls: ['tag-display.scss']
})
export class TagDisplayComponent {

  @Input()
  tag: ITag;
  @Input()
  hideTitle = false;
  @Input()
  hideValue = false;

  text: string;

  constructor() {
    console.log('Hello TagDisplayComponent Component');
    this.text = 'Hello World';
  }
}
