import { Component, Input } from '@angular/core';
import { IDepartment } from 'models/department';

@Component({
  selector: 'app-department-display',
  templateUrl: 'department-display.html',
  styleUrls: ['department-display.scss']
})
export class DepartmentDisplayComponent {

  @Input()
  department: IDepartment;

  text: string;

  constructor() {
    console.log('Hello DepartmentDisplayComponent Component');
    this.text = 'Hello World';
  }

}
