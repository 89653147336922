import { Department, IDepartment } from './../models/department';
import { DepartmentService } from './department.service';
import { QueryObjectService } from './query-object.service';
import { IQueryObject } from '../models/query-object';
import { IQueryResults, QueryResults } from '../models/query-results';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  share,
  tap
  } from 'rxjs';

  @Injectable({
    providedIn: 'root',
  })
export class DepartmentAdminService {

  departments$: Observable<IDepartment[]>;
  department$: Observable<IDepartment>;
  queryResults$: Observable<IQueryResults>;

  private departmentsSubjects = new BehaviorSubject<IDepartment[]>([]);
  private departmentSubject = new BehaviorSubject<IDepartment>(new Department());
  private queryResultsSubject = new BehaviorSubject<IQueryResults>(new QueryResults());

  constructor(private departmentService: DepartmentService, private queryService: QueryObjectService) {
    this.departments$ = this.departmentsSubjects.asObservable();
    this.department$ = this.departmentSubject.asObservable();
    this.queryResults$ = this.queryResultsSubject.asObservable();
  }

  departmentSelected(department: IDepartment) {
    this.departmentSubject.next(department);
  }

  getDepartments(): Observable<IDepartment[]> {
    return this.departmentService.getDepartments().pipe(
      tap(departments => this.departmentsSubjects.next(departments)),
      share()
    );
  }

  postDepartments(department: IDepartment): Observable<IDepartment> {
    console.log('department in admin service: ', department);
    return this.departmentService.postDepartment(department).pipe(
      tap(returnedDepartment => this.departmentSubject.next(returnedDepartment)),
      share()
    );
  }

  postQueryResults(queryObject: IQueryObject): Observable<IQueryResults> {
    return this.departmentService.postDepartmentQuery(queryObject).pipe(
      tap(queryResults => {
        this.queryResultsSubject.next(queryResults);
        this.queryService.broadcastNextQueryResults(queryResults);
      }),
      share()
    );
  }

  putDepartments(department: IDepartment): Observable<number> {
    return this.departmentService.putDepartment(department);
  }
}
