import { Component, Input } from '@angular/core';
import { IRefRange } from 'models/ref-range';

@Component({
  selector: 'app-ref-range-display',
  templateUrl: 'ref-range-display.html',
  styleUrls: ['ref-range-display.scss']
})
export class RefRangeDisplayComponent {

  @Input()
  rr: IRefRange;

  text: string;

  constructor() {
    console.log('Hello RefRangeDisplayComponent Component');
    this.text = 'Hello World';
  }

}
