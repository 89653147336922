import { DepartmentAdminService } from './../../services/department-admin.service';
import { MessageAdminService } from './../../services/message-admin.service';
import { MessagesService } from './../../services/message.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { MarkdownModule } from 'ngx-markdown';
import { DepartmentService } from 'services/department.service';
import { QueryObjectService } from 'services/query-object.service';
import { StartUpService } from 'services/start-up.service';
import { TagService } from 'services/tag.service';
import { TestAdminService } from 'services/test-admin.service';
import { TestService } from 'services/test.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    MarkdownModule.forRoot(),
    CommonModule,
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    MessageAdminService,
    MessagesService,
    TestService,
    TagService,
    StartUpService,
    DepartmentService,
    DepartmentAdminService,
    TestAdminService,
    QueryObjectService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
