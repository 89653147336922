import { environment } from './../src/environments/environment';
import { IQueryObject } from '../models/query-object';
import { IQueryResults } from '../models/query-results';
import { ITest } from '../models/test';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

const HOST = environment.apiURL;

@Injectable({
  providedIn: 'root',
})
export class TestService {
  // private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
  // private options: RequestOptions = new RequestOptions({ headers: this.headers, withCredentials: true});

  // private options : any = {
  //   observe: "response",
  //   responseType: "blob",
  //   headers: new HttpHeaders({
  //       "Accept": "application/json"
  //     })
  // };

  constructor(private http: HttpClient) { }

  getTests(): Observable<ITest[]> {
    return this.http.get(`${HOST}api/Tests`).pipe(catchError(this.handleError));
  }

  getTestById(id: number): Observable<ITest> {
    return this.http.get(`${HOST}api/Tests/${id}`).pipe(catchError(this.handleError));
  }

  getActiveOnlyTestById(id: number): Observable<ITest> {
    return this.http.get(`${HOST}api/Tests/Active/${id}`).pipe(catchError(this.handleError));
  }

  putTest(test: ITest): Observable<any> {
    return this.http.put(`${HOST}api/Tests/${test.id}`, test).pipe(catchError(this.handleError));
  }

  postTest(test: ITest): Observable<any> {
    console.log('tag type service: ', test);
    return this.http.post(`${HOST}api/Tests`, test).pipe(catchError(this.handleError));
  }

  postTestQuery(queryObject: IQueryObject): Observable<IQueryResults> {
    return this.http.post(`${HOST}api/Tests/query`, queryObject).pipe(catchError(this.handleError));
  }

  deleteTests(test: ITest): Observable<ITest> {
    return this.http.delete(`${HOST}api/Tests/${test.id}`).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Observable<any> {
    console.error(error);
    return of(error.json || 'Server Error');
  }
}
