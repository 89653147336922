import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'modal-content',
    loadChildren: () => import('./pages/modal-content/modal-content.module').then( m => m.ModalContentPageModule)
  },
  {
    path: 'main-details',
    loadChildren: () => import('./pages/main-details/main-details.module').then( m => m.MainDetailsPageModule)
  },
  {
    path: 'department-details',
    loadChildren: () => import('./pages/department-details/department-details.module').then( m => m.DepartmentDetailsPageModule)
  },
  {
    path: 'references',
    loadChildren: () => import('./pages/references/references.module').then( m => m.ReferencesPageModule)
  },
  {
    path: 'component-test-references',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./pages/compontent-test-ref-range/component-test-ref-range.module').then( m => m.ComponentTestRefRangePageModule)
  },
  {
    path: 'turn-around-times',
    loadChildren: () => import('./pages/turn-around-times/turn-around-times.module').then( m => m.TurnAroundTimesPageModule)
  },
  {
    path: 'component-tests',
    loadChildren: () => import('./pages/concurrent-tests/concurrent-tests.module').then( m => m.ConcurrentTestsPageModule)
  },
  {
    path: 'synonyms',
    loadChildren: () => import('./pages/synonyms/synonyms.module').then( m => m.SynonymsPageModule)
  },
  {
    path: 'lab-info',
    loadChildren: () => import('./pages/lab-info/lab-info.module').then( m => m.LabInfoPageModule)
  },
  {
    path: 'announcements',
    loadChildren: () => import('./pages/message-modal/message-modal.module').then( m => m.MessageModalPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
