import { ComponentTestRefRangePage } from '../compontent-test-ref-range/component-test-ref-range';
import { MessageModalPage } from '../message-modal/message-modal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IonNav,
  ModalController,
  NavParams,
  ToastController
  } from '@ionic/angular';
import { IMessage } from 'models/message';
import { ITest } from 'models/test';
import { Observable, Subscription } from 'rxjs';
import { MessageAdminService } from 'services/message-admin.service';
import { TestAdminService } from 'services/test-admin.service';

@Component({
  selector: 'app-page-concurrent-tests',
  templateUrl: 'concurrent-tests.html',
  styleUrls: ['concurrent-tests.scss'],
})
export class ConcurrentTestsPage implements OnInit, OnDestroy {

  test$: Observable<ITest>;
  subscriptions: Subscription;
  test: ITest;

  message$: Observable<IMessage>;

  constructor(
        public navParams: NavParams,
        private testService: TestAdminService,
        private toastCtrl: ToastController,
        public modalCtrl: ModalController,
        private messageAdminService: MessageAdminService,
        private nav: IonNav
    ) {
  }

  ngOnInit() {
    this.message$ = this.messageAdminService.message$;
    this.subscriptions = this.message$.subscribe();

    this.test$ = this.testService.test$;

    this.subscriptions.add(this.test$.subscribe(
      {
          next: t => {
            this.test = t;
        }, error: () => {
          this.presentToast('No test selected!');
        }
      }
    ));
    console.log('ionViewDidLoad DepartmentDetailsPage');
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    await toast.present();
  }

  async viewMessageModal() {
    const msgModal = await this.modalCtrl.create({
      component: MessageModalPage
    });
    await msgModal.present();
  }

  async viewComponentTestRefRangeModal() {
    const msgModal = await this.modalCtrl.create({
      component: ComponentTestRefRangePage
    });
    await msgModal.present();
  }

  backToModalContent() {
    this.nav.pop();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
