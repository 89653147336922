import { Component, Input } from '@angular/core';
import { ITest } from 'models/test';

@Component({
  selector: 'app-lab-info',
  templateUrl: 'lab-info.html',
  styleUrls: ['lab-info.scss']
})
export class LabInfoComponent {

  @Input()
  test: ITest;

  text: string;

  constructor() {
    console.log('Hello LabInfoComponent Component');
    this.text = 'Hello World';
  }
}
