import { IContact } from './contact';

export interface IDepartment {
    id: number;
    name: string;
    openingHours: string;
    active: boolean;
    contacts: IContact[];
}

export class Department implements IDepartment {
    id: number;
    name: string;
    openingHours: string;
    active: boolean;
    contacts: IContact[];
}
