import { ModalBaseComponent } from '../modal-base/modal-base.component';
import {
  AfterContentInit,
  Component,
  Input,
  OnInit
  } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IonRouterOutlet, ModalController, ToastController } from '@ionic/angular';
import { Department, IDepartment } from 'models/department';
import { IQueryObject, QueryObject } from 'models/query-object';
import { IQueryResults } from 'models/query-results';
import { ITag } from 'models/tag';
import { Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  tap
  } from 'rxjs/operators';
import { QueryObjectService } from 'services/query-object.service';
import { StartUpService } from 'services/start-up.service';
import { TagService } from 'services/tag.service';
import { TestAdminService } from 'services/test-admin.service';
import { ModalContentPage } from 'src/app/pages/modal-content/modal-content.page';

@Component({
  selector: 'app-test-search-form',
  templateUrl: 'test-search-form.html',
  styleUrls: ['test-search-form.scss']
})

export class TestSearchFormComponent implements OnInit, AfterContentInit {

  @Input() pageSize = 20;

  greeting = 'Welcome to test search form';
  searchTermFormFieldName = 'searchTerm';
  searchForm: FormGroup;
  subscriptions: Subscription;

  queryAlphabetObjectForm: FormGroup;
  queryObjectForm: FormGroup;
  queryObject: IQueryObject;

  searching: boolean;
  queryResults$: Observable<IQueryResults>;
  queryObject$: Observable<IQueryObject>;
  departments$: Observable<Department[]>;

  totalPages: number;
  currentPage: number;

  tags: ITag[];
  departments: IDepartment[];

  filterByLetter: boolean;

  // eslint-disable-next-line max-len
  alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  empty = '';
  allDepts = '';

  constructor(private fb: FormBuilder,
    private queryService: QueryObjectService,
    private toastCtrl: ToastController,
    private testService: TestAdminService,
    private tagService: TagService,
    private startUpService: StartUpService,
    private routerOutlet: IonRouterOutlet,
    public modalCtrl: ModalController,
  ) {
    console.log('Hello SearchFormComponent Component');
  }

  ngOnInit(): void {
    this.departments$ = this.startUpService.departments$;
    this.initQueryForm();
    this.searchForm = this.initSearchForm();
    this.queryObject = this.initQueryObject();

    this.subscriptions = this.queryObjectForm.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.createQueryObjectFromForm();
        if (this.filterByLetter) {
          this.subscriptions.add(this.tagService.getTagsByLetter(this.queryObject).pipe(
            tap(() => this.queryService.broadcastNextQueryObject(this.queryObject))
          ).subscribe({
              next: qr => {
                    this.queryService.broadcastNextQueryResults(qr);
                    this.tags = qr.items;
                    this.totalPages = qr.totalPages;
                    this.currentPage = qr.currentPage;
                    this.searching = false;
                  },
              error: err => {
                  this.searching = false;
                  this.presentToast('Unable to load search results, please check internet connection and retry.');
                  console.log('Unable to load search results.', err);
              }
            }
          ));
        } else {
          this.queryObject.searchTerm = this.searchForm.get('searchTerm').value;
          this.subscriptions.add(this.tagService.postTagTestQuery(this.queryObject).pipe(
              tap(() => this.queryService.broadcastNextQueryObject(this.queryObject))
            ).subscribe({
              next: qr => {
                this.queryService.broadcastNextQueryResults(qr);
                this.tags = qr.items;
                this.totalPages = qr.totalPages;
                this.currentPage = qr.currentPage;
                this.searching = false;
              },
              error: err => {
                this.searching = false;
                this.presentToast('Unable to load search results, please check internet connection and retry.');
                console.log('Unable to load search results.', err);
              }
            }
          ));
        }
      });


    this.subscriptions.add(this.searchForm.valueChanges.pipe(
      tap(() => { this.currentPage = 1; this.totalPages = 1; this.searching = true; }),
      distinctUntilChanged(),
      debounceTime(2000)
    ).subscribe(() => {
        this.filterByLetter = false;

        this.queryAlphabetObjectForm.patchValue({
          searchTerm: '',
          startsWith: ''
        }, { emitEvent: false, onlySelf: true });

        this.queryObjectForm.patchValue({
          currentPage: 1,
          searchTerm: this.searchForm.get('searchTerm').value
        });
      }));

    this.initAlphabetQueryForm();

    this.subscriptions = this.queryAlphabetObjectForm.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => { this.currentPage = 1; this.totalPages = 1; this.searching = true; })
    ).subscribe(vc => {
        if (this.queryAlphabetObjectForm.get('searchTerm').value) {
          this.filterByLetter = true;
        } else {
          this.filterByLetter = false;
        };

        this.searchForm.patchValue({
          searchTerm: ''
        }, { emitEvent: false, onlySelf: true });

        this.queryObjectForm.patchValue({
          filterBy: this.queryAlphabetObjectForm.get('filterBy').value,
          searchTerm: this.queryAlphabetObjectForm.get('searchTerm').value
        });
      });

    this.subscriptions.add(this.departments$.subscribe(depts => {
      console.log('departments: ', depts);
      this.departments = depts;
    }));

    this.currentPage = 1;
    this.totalPages = 1;
  }

  ngAfterContentInit() {
    this.queryObject$ = this.queryService.queryObject$;
    this.queryResults$ = this.queryService.queryResults$;

    this.queryObject$.pipe(first()).subscribe(qo => {
      console.log('ngAfterContentInit QueryObject: ', qo);
      if (qo) {
        this.queryObject = qo;
        this.updateFormsFromQueryObject();
      }
    });

    this.queryService.broadcastLastQueryObject();

    this.queryResults$.pipe(first()).subscribe(qr => {
      console.log('ngAfterContentInit QueryResults: ', qr);
      if (qr) {
        this.tags = qr.items;
        this.currentPage = qr.currentPage;
        this.totalPages = qr.totalPages;
      }
    });
    this.queryService.broadcastLastQueryResults();
  }

  updateFormsFromQueryObject() {

    this.queryObjectForm.patchValue({
      active: this.queryObject.active,
      isSortAscending: this.queryObject.isSortAscending,
      pageSize: this.queryObject.pageSize,
      searchTerm: this.queryObject.searchTerm,
      currentPage: this.queryObject.page,
      filterBy: this.queryObject.filterBy,
      startsWith: this.queryObject.startsWith
    }, { emitEvent: false, onlySelf: true });

    if (this.queryObject.startsWith) {
      this.filterByLetter = true;

      this.queryObjectForm.patchValue({
        searchTerm: ''
      }, { emitEvent: false, onlySelf: true });

      this.queryAlphabetObjectForm.patchValue({
        searchTerm: this.queryObject.searchTerm,
      }, { emitEvent: false, onlySelf: true });

    } else {

      this.searchForm.patchValue({
        searchTerm: this.queryObject.searchTerm,
      }, { emitEvent: false, onlySelf: true });

    }

    this.queryAlphabetObjectForm.patchValue({
      filterBy: this.queryObject.filterBy,
    }, { emitEvent: false, onlySelf: true });

  }

  initQueryForm() {
    this.queryObjectForm = this.fb.group({
      sortBy: [''],
      isSortAscending: [true],
      pageSize: [this.pageSize],
      active: [true],
      searchTerm: [''],
      currentPage: [1],
      filterBy: [''],
      startsWith: ['']
    });
  }

  initAlphabetQueryForm() {
    this.queryAlphabetObjectForm = this.fb.group({
      searchTerm: [''],
      filterBy: ['']
    });
  }

  createQueryObjectFromForm() {
    const newQueryObject = new QueryObject();
    newQueryObject.active = this.queryObjectForm.get('active').value;
    newQueryObject.isSortAscending = this.queryObjectForm.get('isSortAscending').value;
    newQueryObject.pageSize = this.queryObjectForm.get('pageSize').value;
    newQueryObject.searchTerm = this.queryObjectForm.get('searchTerm').value;
    newQueryObject.page = this.currentPage;
    newQueryObject.filterBy = this.queryObjectForm.get('filterBy').value;

    if (this.queryAlphabetObjectForm.get('searchTerm').value) {
      newQueryObject.startsWith = this.queryAlphabetObjectForm.get('searchTerm').value;
      newQueryObject.searchTerm = this.queryAlphabetObjectForm.get('searchTerm').value;
    }

    if (this.queryObject.filterBy !== this.queryObjectForm.get('filterBy').value) {
      newQueryObject.page = 1;
    }
    this.queryObject = newQueryObject;
  }

  pageUp() {
    this.searching = true;
    console.log('page up clicked');
    this.currentPage = this.currentPage + 1;
    this.queryObjectForm.get('currentPage').patchValue(this.currentPage);
  }

  pageDown() {
    this.searching = true;
    this.currentPage = this.currentPage - 1;;
    this.queryObjectForm.get('currentPage').patchValue(this.currentPage);
  }

  initQueryObject(): IQueryObject {
    const newQueryObject = new QueryObject();
    newQueryObject.active = true;
    newQueryObject.isSortAscending = true;
    newQueryObject.page = 1;
    newQueryObject.pageSize = 3;
    newQueryObject.sortBy = '';
    newQueryObject.searchTerm = '';

    return newQueryObject;
  }

  initSearchForm(): FormGroup {
    const searchForm = this.fb.group({
      searchTerm: ['']
    });

    return searchForm;
  }

  filterByDepartment(dept: any) {
    console.log('dept: ', dept);
  }

  selectedTest(testId: number) {
    this.testService.getTestById(testId).subscribe({
      next: () => {
        this.presentModal();
      },
      error: () => {
        this.presentToast('Could not load selected test, please try again!');
      }
    });
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      presentingElement: this.routerOutlet.nativeEl,
      component: ModalBaseComponent,
      componentProps: {
        rootPage: ModalContentPage,
      },
    });

    await modal.present();
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    toast.present();
  }

  save() {
    this.searching = true;
    const searchTerm = this.searchForm.get('searchTerm').value;

    this.searchForm.get('searchTerm').patchValue(searchTerm);
  }

  ionViewWillLeave() {
    this.subscriptions.unsubscribe();
  }
}

