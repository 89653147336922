import { Component, OnDestroy, OnInit } from '@angular/core';
import { IonNav, NavParams, ToastController } from '@ionic/angular';
import { ITest } from 'models/test';
import { Observable, Subscription } from 'rxjs';
import { TestAdminService } from 'services/test-admin.service';

@Component({
  selector: 'app-page-component-test-ref-range',
  templateUrl: 'component-test-ref-range.html',
  styleUrls: ['component-test-ref-range.scss'],
})
export class ComponentTestRefRangePage implements OnInit, OnDestroy {

  test$: Observable<ITest>;
  subscriptions: Subscription;
  test: ITest;

  constructor(
              public navParams: NavParams,
              private testService: TestAdminService,
              private toastCtrl: ToastController,
              private nav: IonNav
            ) {
  }

  ngOnInit() {
    this.test$ = this.testService.componentTest$;

    this.subscriptions = this.test$.subscribe(t => this.test = t );
    console.log('ionViewDidLoad MainDetailsPage');
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    toast.present();
  }

  backToModalContent() {
    this.nav.pop();
  }

  ngOnDestroy() {
    this.testService.nullComponentTest();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
