import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IMessage } from 'models/message';
import { Observable } from 'rxjs';
import { MessageAdminService } from 'services/message-admin.service';

@Component({
  selector: 'app-page-message-modal',
  templateUrl: 'message-modal.html',
  styleUrls: ['message-modal.scss']
})
export class MessageModalPage implements OnInit {

  message$: Observable<IMessage>;

  constructor(
    private messageAdminService: MessageAdminService,
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
    this.message$ = this.messageAdminService.message$;
    this.message$.subscribe();
    console.log('ionViewDidLoad MessageModalPage');
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
