import { Component, Input, OnInit } from '@angular/core';
import { TagTypeEnums } from 'models/Enums';
import { ITag } from 'models/tag';


@Component({
  selector: 'app-synonyms-display',
  templateUrl: 'synonyms-display.html',
  styleUrls: ['synonyms-display.scss']
})
export class SynonymsDisplayComponent implements OnInit {

  @Input()
  tags: ITag[];

  text: string;

  constructor() {
    console.log('Hello SynonymsDisplayComponent Component');
    this.text = 'Hello World';
  }

  ngOnInit(): void {
    this.filterTags();
  }

  filterTags() {
    if (this.tags) {
      console.log('TagTypeEnums.ConcurrentTest.toString()', TagTypeEnums.Synonym.toString());
      this.tags = this.tags.filter(t => t.tagType.code === TagTypeEnums.Synonym.toString());
      console.log('filterTags()', this.tags);
    }
  }

}
