import {
  Component,
  Input,
  OnDestroy,
  OnInit
  } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { GenderEnums } from 'models/Enums';
import { ITest } from 'models/test';
import { Observable, Subscription } from 'rxjs';
import { TestAdminService } from 'services/test-admin.service';


@Component({
  selector: 'app-references-display',
  templateUrl: 'references-display.html',
  styleUrls: ['references-display.scss']
})
export class ReferencesDisplayComponent implements OnInit, OnDestroy{

  @Input()
  test: ITest;
  test$: Observable<ITest>;

  text: string;

  subs: Subscription;

  constructor(
    private testService: TestAdminService,
    private toastCtrl: ToastController) {
  }

  ngOnInit(): void {
    this.test$ = this.testService.test$;

    this.subs = this.test$.subscribe({
      next: t => {
        this.test = t;
        console.log('test in ReferencesDisplayComponent: ', this.test);
      },
      error: () => {
        this.presentToast('No test selected!');
      }
    });

    console.log('RefRangeModalPage init');
  }

  maleRangesToDisplay() {
    return this.test.refRanges.some(rr => rr.gender === GenderEnums.Male);
  }

  femaleRangesToDisplay() {
    return this.test.refRanges.some(rr => rr.gender === GenderEnums.Female);
  }

  notSpecifiedRangesToDisplay() {
    return this.test.refRanges.some(rr => rr.gender === GenderEnums.NotSpecified);
  }


  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    await toast.present();
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
