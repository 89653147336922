// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // apiURL: 'http://localhost:64142/',

  // LIVE
  production: true,
  title: 'Pathology Test List',
  apiURL: 'https://pathology-hb-api-live.azurewebsites.net/',
  thumbnailBlobStorageURL: 'https://pathimages.blob.core.windows.net/thumbnails/',
  fullsizeBlobStorageURL: 'https://pathimages.blob.core.windows.net/fullsize/'

  // UAT
  // production: false,
  // title: 'Pathology Test List: UAT',
  // apiURL: 'https://pathology-hb-api-uat.azurewebsites.net/',
  // thumbnailBlobStorageURL: 'https://pathimagesuat.blob.core.windows.net/thumbnails/',
  // fullsizeBlobStorageURL: 'https://pathimagesuat.blob.core.windows.net/fullsize/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
