import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IQueryObject } from 'models/query-object';
import { IQueryResults } from 'models/query-results';
import { ITag } from 'models/tag';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const HOST = environment.apiURL;

@Injectable({
  providedIn: 'root',
})
export class TagService {
  // private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
  // private options: RequestOptions = new RequestOptions({ headers: this.headers, withCredentials: true});

  // private options = {
  //   observe: 'response',
  //   responseType: 'blob',
  //   headers: new HttpHeaders({
  //       accept: 'application/json'
  //     })
  // };

  constructor(private http: HttpClient) { }

  getTag(): Observable<ITag[]> {
    return this.http.get(`${HOST}api/Tags`).pipe(catchError(this.handleError));
  }

  getTagById(id: number): Observable<ITag> {
    return this.http.get(`${HOST}api/Tags/${id}`)
    .pipe(catchError(this.handleError));
  }

  getTagsByLetter(queryObject: IQueryObject): Observable<IQueryResults> {
    return this.http.post(`${HOST}api/Tags/Letter`, queryObject)
    .pipe(catchError(this.handleError));
  }

  putTag(tag: ITag): Observable<ITag> {
    return this.http.put(`${HOST}api/Tags/${tag.id}`, tag)
    .pipe(catchError(this.handleError));
  }

  postTag(tag: ITag): Observable<ITag> {
    console.log('tag type service: ', tag);
    return this.http.post(`${HOST}api/Tags`, tag)
    .pipe(catchError(this.handleError));
  }

  postConcurrentTest(parentTestId: number, concurrentTestId: number): Observable<ITag> {
    return this.http.post(`${HOST}api/Tags/ParentTestId/${parentTestId}/ConcurrentTestId/${concurrentTestId}`, {})
    .pipe(catchError(this.handleError));
  }

  postSynonym(tag: ITag): Observable<ITag> {
    return this.http.post(`${HOST}api/Tags/Synonym`, tag)
    .pipe(catchError(this.handleError));
  }

  postTagTestQuery(queryObject: IQueryObject): Observable<IQueryResults> {
    return this.http.post(`${HOST}api/Tags/Tests/query`, queryObject)
    .pipe(catchError(this.handleError));
  }

  deleteTag(tag: ITag): Observable<ITag> {
    return this.http.delete(`${HOST}api/Tags/${tag.id}`)
    .pipe(catchError(this.handleError));
  }

  private handleError(error: any): Observable<any> {
    console.error(error);
    return of(error.json || 'Server Error');
  }
}
