import { IMessage } from './../models/message';
import { environment } from '../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

const HOST = environment.apiURL;

@Injectable({
  providedIn: 'root',
})
export class MessagesService {

    // private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
    // private options: RequestOptions = new RequestOptions({ headers: this.headers, withCredentials: true});

    // private options : any = {
    //   observe: 'response',
    //   responseType: 'blob',
    //   headers: new HttpHeaders({
    //       accept: 'application/json'
    //     })
    // };

    constructor(private http: HttpClient) { }

    getMessageById(id: number): Observable<IMessage> {
      return this.http.get(`${HOST}api/Messages/${id}`).pipe(catchError(this.handleError));
    }

    putMessage(message: IMessage): Observable<IMessage> {
      return this.http.put(`${HOST}api/Messages/${message.id}`, message).pipe(catchError(this.handleError));
    }

    deleteMessage(message: IMessage): Observable<IMessage> {
      return this.http.delete(`${HOST}api/Messages/${message.id}`).pipe(catchError(this.handleError));
    }

    private handleError(error: any): Observable<any> {
      console.error(error);
      return of(error.json || 'Server Error');
    }
}
