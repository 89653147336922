import { ConcurrentTestsDisplayComponent } from './concurrent-tests-display/concurrent-tests-display';
import { ContainerNumbersDisplayComponent } from './container-numbers-display/container-numbers-display';
import { DepartmentDisplayComponent } from './department-display/department-display';
import { HeaderComponent } from './header/header.component';
import { LabInfoComponent } from './lab-info/lab-info';
import { ModalBaseComponent } from './modal-base/modal-base.component';
import { RefRangeDisplayComponent } from './ref-range-display/ref-range-display';
import { RefRangeHeadersComponent } from './ref-range-headers/ref-range-headers';
import { ReferencesDisplayComponentComponent } from './references-display-component/references-display-component';
import { ReferencesDisplayComponent } from './references-display/references-display';
import { SynonymsDisplayComponent } from './synonyms-display/synonyms-display';
import { TagDisplayComponent } from './tag-display/tag-display';
import { TatDisplayComponent } from './tat-display/tat-display';
import { TestMainDetailsDisplayComponent } from './test-main-details-display/test-main-details-display';
import { TestSearchFormComponent } from './test-search-form/test-search-form';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
	declarations: [
    TestMainDetailsDisplayComponent,
		TagDisplayComponent,
		DepartmentDisplayComponent,
		ReferencesDisplayComponent,
		ContainerNumbersDisplayComponent,
    TatDisplayComponent,
    ConcurrentTestsDisplayComponent,
		SynonymsDisplayComponent,
		TestSearchFormComponent,
		RefRangeDisplayComponent,
		RefRangeHeadersComponent,
		LabInfoComponent,
    ModalBaseComponent,
    ReferencesDisplayComponentComponent,
    HeaderComponent
	],
	imports: [
		IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule
	],
	exports: [
    TestMainDetailsDisplayComponent,
		TagDisplayComponent,
		DepartmentDisplayComponent,
		ReferencesDisplayComponent,
		ContainerNumbersDisplayComponent,
    TatDisplayComponent,
    ConcurrentTestsDisplayComponent,
		SynonymsDisplayComponent,
		TestSearchFormComponent,
		RefRangeDisplayComponent,
		RefRangeHeadersComponent,
		LabInfoComponent,
    ModalBaseComponent,
    ReferencesDisplayComponentComponent,
    HeaderComponent
	]
})
export class ComponentsModule {}
