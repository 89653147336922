import {
  Component,
  Input,
  OnDestroy,
  OnInit
  } from '@angular/core';
import { ITest } from 'models/test';
import { Subscription } from 'rxjs';
import { TestAdminService } from 'services/test-admin.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-test-main-details-display',
  templateUrl: 'test-main-details-display.html',
  styleUrls: ['test-main-details-display.scss']
})
export class TestMainDetailsDisplayComponent implements OnInit, OnDestroy {

  @Input()
  test: ITest;

  text: string;

  subscriptions: Subscription;

  readonly blobStorageURL = environment.thumbnailBlobStorageURL;

  constructor(private testService: TestAdminService) {
    console.log('Hello TestMainDetailsDisplayComponent Component');
    this.text = 'Hello World';
  }

  ngOnInit(): void {
    this.subscriptions = this.testService.test$.subscribe(t => {
      this.test = t;
      this.sortContainerDetailsByDrawOrder();
    });
  }

  sortContainerDetailsByDrawOrder() {

    if (this.test) {
      if (this.test.containers) {
        const sortedContainerDetails = this.test.containers.slice(0);

        sortedContainerDetails.sort((leftSide, rightSide): number => {
          if (leftSide.drawOrder < rightSide.drawOrder) {
            return -1;
          }
          if (leftSide.drawOrder > rightSide.drawOrder) {
            return 1;
          }
          return 0;
        });

        this.test.containers = sortedContainerDetails.slice(0);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
