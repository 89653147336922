import { MessageModalPage } from './pages/message-modal/message-modal';
import { Component } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ITest } from 'models/test';
import { Subscription } from 'rxjs';
import { StartUpService } from 'services/start-up.service';
import { TestAdminService } from 'services/test-admin.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  pages: Array<{title: string; component: any}>;

  test: ITest;

  subscriptions: Subscription;

  constructor(
    private testService: TestAdminService,
    private toastCtrl: ToastController,
    private startUpService: StartUpService,
    private modalCtrl: ModalController
  ) {
    this.initializeApp();

    // used for an example of ngFor and navigation
    this.pages = [
      { title: 'Home', component: 'HomePage' },
      { title: 'Search', component: 'SearchPage' },
      { title: 'Main Details', component: 'MainDetailsPage' },
      { title: 'Department', component: 'DepartmentDetailsPage' },
      { title: 'Reference Ranges', component: 'ReferencesPage' },
      { title: 'Turn Around Times', component: 'TurnAroundTimesPage' },
      { title: 'Component Tests', component: 'ConcurrentTestsPage' },
      { title: 'Synonyms', component: 'SynonymsPage' },
      { title: 'Laboratory Info', component: 'LabInfoPage' }
    ];

  }

  initializeApp() {

    this.startUpService.getDepartments();
    this.startUpService.getMessage();

    this.subscriptions = this.testService.test$.subscribe(t => {
      this.test = t;
    });

  }

  openPage(page) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    if (this.test || page.title === 'Search' || page.title === 'Home') {
      // this.nav.setRoot(page.component);
    } else {
      this.presentToast('No test has been selected. Please search and select a test to view.');
    }
  }

  async viewMessageModal() {
    const msgModal = await this.modalCtrl.create({
      component: MessageModalPage
    });

    await msgModal.present();
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    await toast.present();
  }
}
