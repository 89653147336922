import { Component, Input } from '@angular/core';
import { ITest } from 'models/test';


@Component({
  selector: 'app-tat-display',
  templateUrl: 'tat-display.html',
  styleUrls: ['tat-display.scss']
})
export class TatDisplayComponent {
  @Input()
  test: ITest;

  text: string;


  constructor() {
    console.log('Hello TatDisplayComponent Component');
    this.text = 'Hello World';
  }

}
