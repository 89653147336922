import { Component, Input, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { TagTypeEnums } from 'models/Enums';
import { ITag } from 'models/tag';
import { TestAdminService } from 'services/test-admin.service';
import { ComponentTestRefRangePage } from 'src/app/pages/compontent-test-ref-range/component-test-ref-range';

@Component({
  selector: 'app-concurrent-tests-display',
  templateUrl: 'concurrent-tests-display.html',
  styleUrls: ['concurrent-tests-display.scss']
})
export class ConcurrentTestsDisplayComponent implements OnInit {

  @Input()
  tags: ITag[];

  text: string;

  componentRefRangePage = ComponentTestRefRangePage;

  constructor(
    public modalCtrl: ModalController,
    private testService: TestAdminService,
    private nav: IonNav,
  ) {
    console.log('Hello ConcurrentTestsDisplayComponent Component');
    this.text = 'Hello World';
  }

  ngOnInit(): void {
    this.filterTags();
  }

  filterTags() {
    if (this.tags) {
      console.log('TagTypeEnums.ConcurrentTest.toString()', TagTypeEnums.ConcurrentTest.toString());
      this.tags = this.tags.filter(t => t.tagType.code === TagTypeEnums.ConcurrentTest.toString());
      console.log('filterTags()', this.tags);
    }
  }

  viewRefRanges(testId: string) {
    const testIdAsNumber = Number(testId);

    this.testService.getComponentTestById(testIdAsNumber).subscribe({
      next: () => {
        console.log('view ref ranges for component test', testId);
            // this.createTestModal();
            this.goToComponentRefRangePage();
          },
      error: err => {
          console.log('viewRefRanges error: ', err);
      }});
  }

  goToComponentRefRangePage() {
    this.nav.push(this.componentRefRangePage, { level: 8 });
  }

}
