import { IQueryObject } from '../models/query-object';
import { IQueryResults } from '../models/query-results';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QueryObjectService {

  queryResults$: Observable<IQueryResults>;
  queryObject$: Observable<IQueryObject>;
  editMode$: Observable<boolean>;

  private queryResultsSubject = new Subject<IQueryResults>();
  private queryObjectSubject = new Subject<IQueryObject>();
  private editSubject: Subject<boolean> = new Subject<boolean>();

  private lastQueryObject: IQueryObject;
  private lastQueryResults: IQueryResults;

  constructor() {
    this.queryResults$ = this.queryResultsSubject.asObservable();
    this.queryObject$ = this.queryObjectSubject.asObservable();
    this.editMode$ = this.editSubject.asObservable();
   }

  broadcastNextQueryObject(queryObject: IQueryObject): void {
    this.lastQueryObject = queryObject;
    console.log('query service las queryObject: ', this.lastQueryObject);
    this.queryObjectSubject.next(queryObject);
  }

  broadcastLastQueryObject(): void {
    if (this.lastQueryObject) {
      this.broadcastNextQueryObject(this.lastQueryObject);
    }
  }

  broadcastNextQueryResults(queryResults: IQueryResults): void {
    this.lastQueryResults = queryResults;
    this.queryResultsSubject.next(queryResults);
  }

  broadcastLastQueryResults(): void {
    if (this.lastQueryResults) {
      this.broadcastNextQueryResults(this.lastQueryResults);
    }
  }

  editModeTrue() {
    this.editSubject.next(true);
  }

  editModeFalse() {
    this.editSubject.next(false);
  }
}
