import { ConcurrentTestsPage } from './../concurrent-tests/concurrent-tests';
import { LabInfoPage } from './../lab-info/lab-info';
import { SynonymsPage } from './../synonyms/synonyms';
import { TurnAroundTimesPage } from './../turn-around-times/turn-around-times';
import { DepartmentDetailsPage } from '../department-details/department-details';
import { MainDetailsPage } from '../main-details/main-details';
import { ReferencesPage } from '../references/references';
import { SearchPage } from '../search/search';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IonNav, ModalController, ToastController } from '@ionic/angular';
import { ITest } from 'models/test';
import { Observable, Subscription } from 'rxjs';
import { TestAdminService } from 'services/test-admin.service';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.page.html',
  styleUrls: ['./modal-content.page.scss'],
})
export class ModalContentPage implements OnInit, OnDestroy {
  level = 0;
  nextPage = MainDetailsPage;
  mainDetailsPage = MainDetailsPage;
  departmentDetailsPage = DepartmentDetailsPage;
  referencesPage = ReferencesPage;
  turnAroundTimesPage = TurnAroundTimesPage;
  componentTestsPage = ConcurrentTestsPage;
  synonymssPage = SynonymsPage;
  labInfoPage = LabInfoPage;

  test$: Observable<ITest>;
  test: ITest;
  subscriptions: Subscription;

  constructor(
    private modalController: ModalController,
    private nav: IonNav,
    private testService: TestAdminService,
    private toastCtrl: ToastController,
    ) { }

  ngOnInit() {
    this.test$ = this.testService.test$;

    this.subscriptions = this.test$.subscribe({
        next: t => {
          console.log('test from main details: ', t);
          this.test = t;
        },
        error: () => {
            this.presentToast('No test selected!');
          }
      });
  }

  goForward() {
    this.nav.push(this.nextPage, { level: this.level + 1 });
  }

  goToMainDetailsPage() {
    this.nav.push(this.mainDetailsPage, { level: 1 });
  }

  goToDepartmentDetailsPage() {
    this.nav.push(this.departmentDetailsPage, { level: 2 });
  }

  goToRefRangePage() {
    this.nav.push(this.referencesPage, { level: 3 });
  }

  goToTurnAroundTimesPage() {
    this.nav.push(this.turnAroundTimesPage, { level: 4 });
  }

  goToComponentTestsPage() {
    this.nav.push(this.componentTestsPage, { level: 5 });
  }

  goToSynonymsPage() {
    this.nav.push(this.synonymssPage, { level: 6 });
  }

  goToLabInfoPage() {
    this.nav.push(this.labInfoPage, { level: 7 });
  }

  goRoot() {
    this.nav.popToRoot();
  }

  close() {
    this.modalController.dismiss();
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    await toast.present();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
