import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MessageModalPage } from 'src/app/pages/message-modal/message-modal';
/* eslint-disable max-len */

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  subs: Subscription;

  currentRoute: string;

  isSearchPage: boolean;

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    public navCtrl: NavController,
  ) { }

  ngOnInit() {

    this.subs = new Subscription();

    this.subs.add(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        console.log('url changed to: ', this.currentRoute);
        this.isSearchPage = this.router.isActive('search', { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
        console.log('isSearchPage: ', this.isSearchPage);
      }
    }));
  }

  openSearch() {
    this.router.navigate(['/search']);
  }

  goHome() {
    this.router.navigate(['/home']);
  }

  async openAnnouncements() {
    const msgModal = await this.modalCtrl.create({
      component: MessageModalPage
    });
    await msgModal.present();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

}
