import { IDepartment } from './../models/department';
import { environment } from './../src/environments/environment';
import { IQueryObject } from '../models/query-object';
import { IQueryResults } from '../models/query-results';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const HOST = environment.apiURL;

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {

  // private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
  // private options: RequestOptions = new RequestOptions({ headers: this.headers, withCredentials: true});

  // private options : any = {
  //   observe: "response",
  //   responseType: "blob",
  //   headers: new HttpHeaders({
  //       "Accept": "application/json"
  //     })
  // };

  constructor(private http: HttpClient) { }

  getDepartments(): Observable<IDepartment[]> {
    return this.http.get(`${HOST}api/Departments/Active`).pipe(catchError(this.handleError));
  }

  getDepartmentById(id: number): Observable<IDepartment> {
    return this.http.get(`${HOST}api/Departments/${id}`).pipe(catchError(this.handleError));
  }

  putDepartment(department: IDepartment): Observable<number> {
    return this.http.put(`${HOST}api/Departments/${department.id}`, department).pipe(catchError(this.handleError));
  }

  postDepartment(department: IDepartment): Observable<IDepartment> {
    console.log('tag type service: ', department);
    return this.http.post(`${HOST}api/Departments`, department).pipe(catchError(this.handleError));
  }

  postDepartmentQuery(queryObject: IQueryObject): Observable<IQueryResults> {
    return this.http.post(`${HOST}api/Departments/query`, queryObject).pipe(catchError(this.handleError));
  }

  deleteDepartment(department: IDepartment): Observable<IDepartment> {
    return this.http.delete(`${HOST}api/Departments/${department.id}`).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Observable<any> {
    console.error(error);
    return of(error.json || 'Server Error');
  }
}
